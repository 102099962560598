import { Form } from "antd";

const GetProviderTypesProps = () => {
  const [createProviderTypes] = Form.useForm();
  const [updateProviderTypes] = Form.useForm();
  const [searchProviderTypesForm] = Form.useForm();
  const [archiveProviderTypes] = Form.useForm();

  const createProviderTypesFormProps = {
    formMainProps: {
      form: createProviderTypes,
    },
  };

  const updateProviderTypesFormProps = {
    formMainProps: {
      form: updateProviderTypes,
    },
  };

  const searchProviderTypesFormProps = {
    formMainProps: {
      form: searchProviderTypesForm,
    },
  };

  const archiveProviderTypesFormProps = {
    formMainProps: {
      form: archiveProviderTypes,
    },
  };

  return {
    createProviderTypesFormProps,
    updateProviderTypesFormProps,
    searchProviderTypesFormProps,
    archiveProviderTypesFormProps,
    createProviderTypes,
    updateProviderTypes,
    searchProviderTypesForm,
    archiveProviderTypes,
  };
};

export default GetProviderTypesProps;
