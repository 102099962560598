import { message } from "antd";
import { CreateProviderTypes } from "../../api/CreateProviderTypes";
import { useResponseProviderTypes } from "../global/useResponseProviderTypes";

export const useCreateProviderTypes = async (payload: any) => {
  await message.loading("Creating Provider Type..", 2);
  const response = await CreateProviderTypes(payload);

  return useResponseProviderTypes(response);
};
