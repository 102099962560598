import { fetch } from "./API";

export const FetchProviderTypes = async (query: {} | undefined) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_API_URL}/provider-types`,
      { ...query },
      {}
    );
  } catch (err: any) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};
