import { useState } from "react";

export const useChangeUpdateProviderTypesModalStatus = () => {
  const [status, setStatus] = useState(false);

  let onChange = () => {
    const status_ = status ? false : true;
    setStatus(status_);
  };

  return {
    onChange,
    status,
  };
};
