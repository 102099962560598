import { useEffect, useState } from "react";

export const useReturnGeneralInformation = () => {
  const [generalInformation, setGeneralInformation] = useState(null);
  const genInfo = JSON.parse(JSON.stringify(localStorage.getItem("userInfo")));

  useEffect(() => {
    if (genInfo) {
      setGeneralInformation(genInfo);
    }
  }, []);

  return generalInformation;
};
