import { useState } from "react";

export const useChangeArchiveProviderTypesModalStatus = () => {
  const [status, setStatus] = useState(false);

  let onChange = () => {
    const _status = status ? false : true;
    setStatus(_status);
  };

  return {
    onChange,
    status,
  };
};
