import { ReactNode, Key } from "react";
import { MenuItemInteface } from "../interfaces/MenuComponentInterfaces";

const getItem_ = (
  label: ReactNode,
  key: Key,
  icon?: ReactNode,
  children?: MenuItemInteface[],
  link?: string | null
) => {
  return {
    key,
    icon,
    children,
    label,
    link,
  } as MenuItemInteface;
};

export const getItem = getItem_;
