export const providers_area_of_coverage = [
  {
    provider_name: "Chailease Berjaya",
    installment_location_reference_names: ["NCR"],
  },
  {
    provider_name: "Yulon",
    installment_location_reference_names: [
      "NCR",
      "Laguna",
      "Cavite",
      "Rizal",
      "Bulacan",
      "Pampanga",
      "Batangas",
    ],
  },
  {
    provider_name: "Welcome Bank",
    installment_location_reference_names: [
      "NCR",
      "Rizal",
      "Laguna",
      "Batangas",
      "Davao",
      "Cebu",
      "Tagbilaran",
      "Bacolod",
      "Bulacan",
      "Pampanga",
      "Cavite",
    ],
  },
  {
    provider_name: "Orico Finance",
    installment_location_reference_names: ["NCR"],
  },
  {
    provider_name: "EWB",
    installment_location_reference_names: [
      "NCR",
      "Rizal",
      "Laguna",
      "Batangas",
      "Davao",
      "Cebu",
      "Tagbilaran",
      "Bacolod",
      "Bulacan",
      "Pampanga",
      "Cavite",
    ],
  },
  {
    provider_name: "SBC",
    installment_location_reference_names: [
      "NCR",
      "Rizal",
      "Laguna",
      "Batangas",
      "Davao",
      "Cebu",
      "Tagbilaran",
      "Bacolod",
      "Bulacan",
      "Pampanga",
      "Cavite",
    ],
  },
  {
    provider_name: "JACCS FINANCE",
    installment_location_reference_names: ["NCR"],
  },
];
