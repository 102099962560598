import { Typography } from "antd";

type PopOverTitleProps = {
  generalInfo: string | null;
  roleInfo: string | null;
};

const { Text } = Typography;

export const PopOverTitleComponent = ({
  generalInfo,
  roleInfo,
}: PopOverTitleProps) => {
  const isInformationExists = generalInfo && roleInfo ? true : false;

  const returnGeneralInfo = () => {
    const parsedGeneralInfo = generalInfo ? JSON.parse(generalInfo) : {};

    const { first_name, middle_name, last_name, email_address } =
      parsedGeneralInfo;

    var fullName = "";

    if (first_name) {
      fullName += `${first_name} `;
    }

    if (middle_name) {
      fullName += `${middle_name} `;
    }

    if (last_name) {
      fullName += `${last_name} `;
    }

    const { name = null } = roleInfo ? JSON.parse(roleInfo) : {};

    return {
      fullName,
      emailAddress: email_address,
      roleName: name,
    };
  };

  return (
    <div
      style={{
        textAlign: "center",
        padding: "4% 1% 0% 0%",
        margin: "auto",
      }}
    >
      <Text>{isInformationExists ? returnGeneralInfo().fullName : "User"}</Text>
      <br />
      <Text type="secondary">
        {isInformationExists ? returnGeneralInfo().emailAddress : "N/A"}
      </Text>
      <div>{isInformationExists ? returnGeneralInfo().roleName : "N/A"}</div>
    </div>
  );
};
