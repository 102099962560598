import { Button, Input, Form, DatePicker } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { FilterChipsProps } from "../../../helpers/interfaces/FilteringInterfaces";
import { FilterChipsComponent } from "../../global/FilterChipsComponent";

const { RangePicker } = DatePicker;

type ProviderTypesFilteringProps = {
  onClickSearch?: () => void;
  form: any;
  isDataFiltered: boolean;
  providerTypesTableMeta: any;
  resetSearch: () => void;
  selectedRow: any;
  filterChips: Array<any>;
};

export const ProviderTypesFilteringComponent = ({
  onClickSearch,
  form,
  isDataFiltered,
  resetSearch,
  filterChips,
}: ProviderTypesFilteringProps) => {
  return (
    <>
      <div style={{ marginTop: "2%", width: "100%" }}>
        <Form
          form={form.formMainProps.form}
          layout="inline"
          labelAlign="right"
          style={{ justifyContent: "flex-start" }}
        >
          <Form.Item name="query_search_value">
            <Input placeholder="Search Provider Type" />
          </Form.Item>
          {/* <Form.Item name="created_at" label="Date Created">
            <RangePicker />
          </Form.Item> */}
          <Button
            type="primary"
            icon={<SearchOutlined />}
            onClick={onClickSearch}
          >
            Search
          </Button>
        </Form>
      </div>
      <br />
      <div>
        {isDataFiltered ? (
          <FilterChipsComponent
            isShowClearFilters={true}
            onClickClearFilters={resetSearch}
            chips={filterChips.length > 0 ? filterChips : []}
          />
        ) : null}
      </div>
    </>
  );
};
