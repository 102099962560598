import { Table } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { AreaOfCoveragesData } from "../../helpers/interfaces/AreaOfCoverageInterfaces";
import App from "../../App";

type TableProps = {
  columns: ColumnsType<any>;
  data: Array<any>;
  pagination: TablePaginationConfig;
  onSelectRow: any;
  selectedRowKey: any;
  selectionType: any;
};

/**
 * 
 * 
 * pagination={{
        total: data.total,
        onChange: onPaginationChange,
        current: currentPagination,
      }}
 * 
 * =
 */

export const TableComponent = ({
  columns,
  data,
  pagination,
  onSelectRow,
  selectedRowKey,
  selectionType = "radio",
}: TableProps) => {
  const expandedRowRender = (record: AreaOfCoveragesData) => {
    const nestedColumns = [
      {
        title: "Province",
        dataIndex: "province_name",
        key: "province_name",
      },
      {
        title: "City",
        dataIndex: "city_name",
        key: "city_name",
      },
    ];

    const nestedData = record?.providersAreaOfCoverage?.map((d, i) => {
      return {
        key: i,
        city_name: d?.city_name,
        province_name: d?.province_name,
      };
    });

    return (
      <>
        <div className="expanded-table">
          <strong>Area of Coverages:</strong>
          <Table
            columns={nestedColumns}
            dataSource={nestedData}
            pagination={false}
          />
        </div>
      </>
    );
  };

  return (
    <>
      {selectionType ? (
        <Table
          columns={columns}
          dataSource={data}
          size="small"
          pagination={pagination}
          bordered
          rowSelection={{
            type: selectionType,
            selectedRowKeys:
              selectionType === "radio" ? [selectedRowKey] : selectedRowKey,
            onChange: onSelectRow,
          }}
        />
      ) : (
        // FOR AREA OF COVERAGE TABLE
        <Table
          columns={columns}
          expandable={{
            rowExpandable: (record) => true,
            expandedRowRender: expandedRowRender,
          }}
          dataSource={data}
          size="small"
          pagination={pagination}
          bordered
        />
      )}
    </>
  );
};
