import type { ColumnsType } from "antd/es/table";
import { TableComponent } from "../../global/TableComponent";
import { AreaOfCoveragesData } from "../../../helpers/interfaces/AreaOfCoverageInterfaces";

const columns: ColumnsType<AreaOfCoveragesData> = [
  {
    title: "Id",
    dataIndex: "provider_id",
    key: "provider_id",
  },
  {
    title: "Provider Name",
    dataIndex: "provider_name",
    key: "provider_name",
  },
  {
    title: "Created At",
    key: "created_at",
    dataIndex: "created_at",
  },
];

export const ProvidersAreaOfCoverageTableComponent = (props: any) => {
  const { tableProps, onPaginationChange, onSelectRow, selectedRowKey } = props;

  return (
    <div style={{ marginTop: "2%" }}>
      <TableComponent
        columns={columns}
        data={tableProps?.data ? tableProps.data.data : []}
        pagination={
          tableProps?.data
            ? { ...tableProps.data.meta, onChange: onPaginationChange }
            : {}
        }
        onSelectRow={onSelectRow}
        selectedRowKey={selectedRowKey}
        selectionType={null}
      />
    </div>
  );
};
