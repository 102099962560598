export const errorLogger = (error: any) => {
  if (error.response) {
    return error.response;
  }

  if (error.request) {
    return error.request;
  }

  if (error) {
    return error.message;
  }

  return error;
};
