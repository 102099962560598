import { useEffect, useState } from "react";
import { message, Modal, notification } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { PlatformIndexComponent } from "../components/platforms/PlatformIndexComponent";
import { useChangeUpdateModalStatus } from "../hooks/platforms/useChangeUpdateModalStatus";
import { useChangeCreateModalStatus } from "../hooks/platforms/useChangeCreateModalStatus";
import { useChangeArchiveModalStatus } from "../hooks/platforms/useChangeArchiveModalStatus";
import { useFetchPlatforms } from "../hooks/platforms/useFetchPlatforms";
import { useCreatePlatform } from "../hooks/platforms/useCreatePlatform";
import { useUpdatePlatform } from "../hooks/platforms/useUpdatePlatforms";
import GetPlatformProps from "../helpers/functions/getPlatformFormProps";

/** INTERFACE FOR FILTERCHIPS */

interface filterChips {
  name?: any;
  onClose?: () => void;
}

const chipsDefaultValue: Array<filterChips> = [];

export const PlatformsPage = () => {
  const [platformsTableMeta, setPlatformsTableMeta] = useState({
    page: 1,
    limit: 10,
    query_search_value: "",
  });

  const [filterChips, setFilterChips] = useState(chipsDefaultValue);

  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowKey, setSelectedRowKey] = useState(0);
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  const [isDataFiltered, setIsDataFiltered] = useState(false);

  const createPlatformModal = useChangeCreateModalStatus();
  const updatePlatformModal = useChangeUpdateModalStatus();
  const archivePlatformModal = useChangeArchiveModalStatus();
  const {
    archivePlatformFormProps,
    createPlatformFormProps,
    searchPlatformFormProps,
    updatePlatformFormProps,
    archivePlatform,
    createPlatform,
    searchPlatformForm,
    updatePlatform,
  } = GetPlatformProps();

  let platformsList = useFetchPlatforms(platformsTableMeta);
  const platformCreateData = useCreatePlatform;
  const updatePlatformData = useUpdatePlatform;

  const onPaginationChange = (page: any) => {
    setPlatformsTableMeta({ ...platformsTableMeta, page: page });
  };

  /*  for selecting rows in table using radio button */
  const onSelectRow = (
    selectedRowIds: Array<number>,
    selectedRowInfos: Array<any>
  ) => {
    setSelectedRow(selectedRowInfos[0]);
    setSelectedRowKey(selectedRowIds[0]);
    setIsDataFiltered(true);
    setFilterChips([
      {
        name: `Selected: ${selectedRowInfos[0].platform_name}`,
        onClose: () => {
          setSelectedRow(null);
          setSelectedRowKey(0);
          setIsDataFiltered(false);
          setFilterChips([]);
        },
      },
    ]);
  };

  /* useEffect for update function */
  useEffect(() => {
    if (selectedRow) {
      updatePlatform?.setFieldsValue(selectedRow);
    }
  }, [selectedRow]);

  const onRemoveAllSearches = () => {
    createPlatform.resetFields();
    updatePlatform.resetFields();
    archivePlatform.resetFields();
  };

  /** FUNCTION FOR CREATE */

  const onClickCreatePlatform = async () => {
    setIsConfirmLoading(true);
    const isEmptyFields = Object.values(createPlatform.getFieldsValue()).some(
      (value) => !value
    );

    if (isEmptyFields) {
      notification.error({
        message: "The platform name field cannot be blank.",
      });
      return;
    }
    const values = await createPlatform.validateFields();

    // check if the userInfo get the local storage users id
    const userInfo = localStorage.getItem("userInfo");

    if (userInfo) {
      const { id } = JSON.parse(userInfo);
      values["created_by"] = id ? id : 0;
    }

    // check if the platform name has white space
    const platformName = values["platform_name"];
    const hasNonWhitespaceCharacters = platformName.trim().length > 0;
    if (hasNonWhitespaceCharacters) {
      const isSuccess = await platformCreateData(values);
      if (isSuccess) {
        createPlatformModal.onChange();
        onRemoveAllSearches();
        setPlatformsTableMeta({ ...platformsTableMeta, limit: 10 });
      }
    } else {
      message.error("Please input a valid platform name");
    }
    setIsConfirmLoading(false);
  };

  /** FUNCTION FOR UPDATE */

  const onClickUpdatePlatform = async () => {
    const isEmptyFields = Object.values(updatePlatform.getFieldsValue()).some(
      (value) => !value
    );

    if (isEmptyFields) {
      notification.error({
        message: "The platform name field cannot be blank.",
      });
      return;
    }
    const values = await updatePlatform.validateFields();
    const platform_name = values.platform_name;

    var isOnlyWhiteSpace: boolean = false;

    const updateForPlatformChar = platform_name.split();

    for (const updateChar of updateForPlatformChar) {
      isOnlyWhiteSpace = updateChar === " " ? true : false;
    }

    if (isOnlyWhiteSpace) {
      notification.error({
        message: "Update Platform cannot be blank.",
      });
      return;
    }

    const isSuccess = await updatePlatformData(selectedRowKey, values);
    if (isSuccess) {
      updatePlatformModal.onChange();
      onRemoveAllSearches();
      setPlatformsTableMeta({ ...platformsTableMeta, limit: 10 });
      setSelectedRowKey(0);
      setSelectedRow(null);
      setIsDataFiltered(false);
    }
  };

  /** FUNCTION FOR ARCHIVE */
  const { confirm } = Modal;

  const OnUpdateStatus = async () => {
    await message.info("To proceed kindly provide reason for archiving", 2);
    archivePlatformModal.onChange();
  };

  const onClickArchivePlatform = async () => {
    confirm({
      centered: true,
      title: "Archive Platform",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure you want to archive this platform?",
      async onOk() {
        // Check if form fields are not empty
        const isEmptyFields = Object.values(
          archivePlatform.getFieldsValue()
        ).some((value) => !value);

        if (isEmptyFields) {
          notification.error({
            message: "Please provide a reason for archiving this platform.",
          });
          return;
        }

        const values = await archivePlatform.validateFields();
        const payload = { is_active: 0 };
        const reason_for_archiving = values.reason_for_archiving;

        var isOnlyWhiteSpace: boolean = false;

        const reasonForArchvingChar = reason_for_archiving.split();

        for (const reasonChar of reasonForArchvingChar) {
          isOnlyWhiteSpace = reasonChar === " " ? true : false;
        }

        // Check if the reason_for_archiving field contains only whitespace or is empty

        if (isOnlyWhiteSpace) {
          notification.error({
            message: "Reason for archiving cannot be empty.",
          });
          return;
        }

        const isSuccess = await updatePlatformData(selectedRowKey, payload);
        if (isSuccess) {
          if (payload.is_active === 0) {
            notification.destroy();
            notification.success({
              message: "Platform has been archived",
            });
          }

          onRemoveAllSearches();
          setPlatformsTableMeta({ ...platformsTableMeta, limit: 10 });
          setSelectedRowKey(0);
          setSelectedRow(null);
          setIsDataFiltered(false);
          archivePlatformModal.onChange();
        }
      },
      onCancel() {
        return;
      },
    });
  };

  /** FUNCTION FOR SEARCH */

  const onSearch = async () => {
    const values = await searchPlatformForm.validateFields();
    if (values) {
      setIsDataFiltered(true);
      setPlatformsTableMeta((previousState) => {
        return {
          ...previousState,
          query_search_value: values.query_search_value,
        };
      });
      setFilterChips((prevState) => {
        return [
          ...prevState,
          { name: values.query_search_value, onClose: () => {} },
        ];
      });
    }
  };

  const resetSearch = () => {
    setPlatformsTableMeta((previousState) => {
      return {
        ...previousState,
        query_search_value: "",
      };
    });

    searchPlatformForm.resetFields();
    setIsDataFiltered(false);
    setSelectedRowKey(0);
    setSelectedRow(null);
    setFilterChips([]);
  };

  // compose Props

  const platformIndexProps = {
    createModalStatus: createPlatformModal.status,
    updateModalStatus: updatePlatformModal.status,
    archiveModalStatus: archivePlatformModal.status,
    handleChangeCreatePlatformModalStatus: createPlatformModal.onChange,
    handleChangeUpdatePlatformModalStatus: updatePlatformModal.onChange,
    handleChangeArchivePlatformModalStatus: archivePlatformModal.onChange,
    createPlatformFormProps,
    updatePlatformFormProps,
    searchPlatformFormProps,
    archivePlatformFormProps,
    onClickUpdatePlatform,
    onClickCreatePlatform,
    onClickArchivePlatform,
    platformsList,
    onPaginationChange,
    onSelectRow,
    isConfirmLoading,
    selectedRowKey,
    onSearch,
    isDataFiltered,
    platformsTableMeta,
    resetSearch,
    OnUpdateStatus,
    selectedRow,
    filterChips,
  };

  return <PlatformIndexComponent {...platformIndexProps} />;
};
