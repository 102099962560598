import { useCallback, useEffect, useState } from "react";
import { message, Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useRevalidateUser } from "../hooks/auth/useRevalidateUser";
import { useNotification } from "../hooks/global/useOpenNotification";
import { useReturnGeneralInformation } from "../hooks/global/useReturnGeneralInformation";
import { useReturnGetRoleInformation } from "../hooks/global/useReturnGetRoleInformation";
import { useQueryUser } from "../hooks/user/useQueryUser";
import { UserInformationInterface } from "../helpers/interfaces/UserInterfaces";
import { AppLayoutIndexComponent } from "../components/main/AppLayoutIndexComponent";

const { confirm } = Modal;
const user: UserInformationInterface = {};

interface route {
  component: any;
}

type AppPageProps = {
  route: route;
};

export const AppLayout = ({ route }: AppPageProps) => {
  const [openDrawerStatus, setOpenDrawerStatus] = useState(false);
  const [userPopDownStatus, setUserPopDownStatus] = useState(false);
  const [loggedInUserInfo, setLoggedInUserInfo] = useState(user);

  const navigate = useNavigate();
  const { validate } = useRevalidateUser();
  const { show, contextHolder } = useNotification();
  const { queryUser } = useQueryUser();
  const generalInfo = useReturnGeneralInformation();
  const roleInfo = useReturnGetRoleInformation();

  useEffect(() => {
    !localStorage.getItem("jwt") ? navigate("/login") : reAuthenticateUser();
  }, []);

  const reAuthenticateUser = useCallback(async () => {
    await message.loading("validating user permission and credentials", 3);
    const isUserAuthenticated = await validate();

    if (!isUserAuthenticated) {
      show({
        type: "error",
        message: "Revalidating User",
        description:
          "User credential is not permitted, please relogin to proceed",
        placement: "topRight",
        duration: 4,
      });
      navigate("/login");
      return;
    }

    if (generalInfo) {
      const { email_address } = generalInfo;
      const user = await queryUser(email_address);
      const role = localStorage.getItem("role");
      user["roleName"] = role ? JSON.parse(role).name : null;
      setLoggedInUserInfo(user);
      navigate("/dashboard");
    }
  }, []);

  const onClickLogout = () => {
    confirm({
      title: "Are you sure, you want to logout?",
      icon: <ExclamationCircleFilled />,
      content: "When clicked the OK button, all changes will not saved",
      onOk() {
        navigate("/login");
        localStorage.clear();
      },
      onCancel() {},
    });
  };

  const onClickViewProfile = () => {
    if (userPopDownStatus) {
      onHandleHoverChange();
    }

    setOpenDrawerStatus(!openDrawerStatus);
  };

  const onHandleHoverChange = () => {
    setUserPopDownStatus(!userPopDownStatus);
  };

  return (
    <AppLayoutIndexComponent
      contextHolder={contextHolder}
      generalInfo={generalInfo}
      loggedInUserInfo={loggedInUserInfo}
      onClickLogout={onClickLogout}
      onClickViewProfile={onClickViewProfile}
      onHandleHoverChange={onHandleHoverChange}
      openDrawerStatus={openDrawerStatus}
      roleInfo={roleInfo}
      userPopDownStatus={userPopDownStatus}
      route={route}
    />
  );
};
