import { Space, Divider, Button } from "antd";
import { InfoCircleOutlined, LogoutOutlined } from "@ant-design/icons";

type PopOverContentProps = {
  onClickViewProfile: () => void;
  onClickLogout: () => void;
};

export const PopOverContentComponent = ({
  onClickViewProfile,
  onClickLogout,
}: PopOverContentProps) => {
  return (
    <Space
      direction="vertical"
      style={{ textAlign: "center", display: "flex" }}
      size={2}
    >
      <Divider />
      <Button
        size="small"
        type="text"
        block
        icon={<InfoCircleOutlined />}
        onClick={onClickViewProfile}
      >
        View Profile
      </Button>
      <Button
        size="small"
        type="text"
        block
        icon={<LogoutOutlined />}
        onClick={onClickLogout}
      >
        Logout
      </Button>
    </Space>
  );
};
