import { Typography, Breadcrumb, Divider } from "antd";
import { HomeOutlined } from "@ant-design/icons";

const { Title } = Typography;

interface PageHeaderProps {
  level: 1 | 2 | 3 | 4 | 5;
  title: string;
  breadCrumbs: Array<{
    path: string;
    breadcrumbName: string;
  }>;
}

export const PageHeaderComponent = (props: PageHeaderProps) => {
  return (
    <>
      <Title
        level={props.level}
        style={{ fontWeight: "350", fontSize: "larger" }}
      >
        {props.title}
      </Title>
      <Breadcrumb>
        <Breadcrumb.Item>
          <HomeOutlined />
        </Breadcrumb.Item>
        {props.breadCrumbs.map((item) => (
          <Breadcrumb.Item href={item.path}>
            {item.breadcrumbName}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
      <Divider />
    </>
  );
};
