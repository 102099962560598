import { Button, Input, Form, DatePicker } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { FilterChipsProps } from "../../../helpers/interfaces/FilteringInterfaces";
import { FilterChipsComponent } from "../../global/FilterChipsComponent";

// const { RangePicker } = DatePicker;

type ProvidersAreaOfCoverageFilteringProps = {
  onClickSearch?: () => void;
  filters?: FilterChipsProps;
};

export const ProvidersAreaOfCoverageFilteringComponent = ({
  onClickSearch,
  filters,
}: ProvidersAreaOfCoverageFilteringProps) => {
  return (
    <>
      <div style={{ marginTop: "2%", width: "100%" }}>
        <Form
          layout="inline"
          labelAlign="right"
          style={{ justifyContent: "flex-start" }}
        >
          {/* <Form.Item name="general_search_value">
            <Input placeholder="Search Area of Coverage" />
          </Form.Item> */}
          {/* <Form.Item label="Date Created">
            <RangePicker />
          </Form.Item> */}
          {/* <Button
            type="primary"
            icon={<SearchOutlined />}
            onClick={onClickSearch}
          >
            Search
          </Button> */}
        </Form>
      </div>
      <div>
        {filters ? (
          filters.isShowClearFilters ? (
            <FilterChipsComponent {...filters} />
          ) : null
        ) : null}
      </div>
    </>
  );
};
