import type { ColumnsType } from "antd/es/table";
import { TableComponent } from "../../global/TableComponent";
import { ProviderTypesData } from "../../../helpers/interfaces/ProviderTypesInterfaces";

const columns: ColumnsType<ProviderTypesData> = [
  {
    title: "Id",
    dataIndex: "provider_type_id",
    key: "provider_type_id",
  },
  {
    title: "Provider Type Name",
    dataIndex: "provider_type_name",
    key: "provider_type_name",
  },
  {
    title: "Created At",
    key: "created_at",
    dataIndex: "created_at",
  },
];

export const ProviderTypesTableComponent = (props: any) => {
  const {
    tableProps,
    onPaginationChange,
    onSelectRow,
    selectedRowKey,
    selectionType,
  } = props;

  return (
    <div style={{ marginTop: "2%" }}>
      <TableComponent
        columns={columns}
        data={tableProps?.data ? tableProps.data.data : []}
        pagination={
          tableProps?.data
            ? { ...tableProps.data.meta, onChange: onPaginationChange }
            : {}
        }
        onSelectRow={onSelectRow}
        selectedRowKey={selectedRowKey}
        selectionType={selectionType}
      />
    </div>
  );
};
