import { Typography } from "antd";

export const SiderTitleComponent = () => {
  return (
    <Typography.Title
      level={5}
      style={{
        padding: "0% 2% 1% 6%",
        fontSize: "6px !important",
        textAlign: "center",
        color: "darkslategrey",
        fontWeight: 450,
      }}
    >
      INSTALLMENT DASHBOARD
    </Typography.Title>
  );
};
