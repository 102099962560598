import { Divider } from "antd";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import {
  FileSearchOutlined,
  SettingOutlined,
  BlockOutlined,
  BankOutlined,
  TableOutlined,
} from "@ant-design/icons";
import { useMenuOpenKeys } from "../../../hooks/global/useOpenMenuKeys";
import { getItem } from "../../../helpers/functions/menuFunctions";
import { MenuItemInteface } from "../../../helpers/interfaces/MenuComponentInterfaces";

const { SubMenu } = Menu;

let items: MenuItemInteface[] = [
  getItem("Dashboard", "sub0", <BlockOutlined />, [], "/dashboard"),
  getItem("Platforms", "sub1", <BlockOutlined />, [], "/platforms"),
  getItem(
    "Providers",
    "sub2",
    <BankOutlined />,
    [
      getItem("Providers", "5", null, [], "/providers"),
      getItem("Provider Types", "6", null, [], "/provider-types"),
      getItem(
        "Area of Coverages",
        "7",
        null,
        [],
        "/providers/area-of-coverage"
      ),
    ],
    null
  ),
  getItem(
    "Installment Histories",
    "sub3",
    <TableOutlined />,
    [],
    "/installment-histories"
  ),
  getItem(
    "Settings",
    "sub4",
    <SettingOutlined />,
    [
      getItem("Custom Fields", "9", null, [], "/settings/custom-fields"),
      getItem("Meta Fields", "10", null, [], "/settings/meta-fields"),
      getItem(
        "Custom Field Types",
        "11",
        null,
        [],
        "/settings/custom-fields/types"
      ),
      getItem(
        "Custom Field Groups",
        "12",
        null,
        [],
        "/settings/custom-fields/groups"
      ),
    ],
    null
  ),
  getItem(
    "Activity Logs",
    "sub5",
    <FileSearchOutlined />,
    [],
    "/activity-logs"
  ),
  getItem("Options", "sub6", <SettingOutlined />, [], "/options"),
];

export const SiderNavigationComponent = () => {
  const { openKeys, onOpenChange } = useMenuOpenKeys();

  return (
    <>
      <Divider style={{ marginTop: "10%" }} />
      <Menu
        mode="inline"
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        style={{
          width: "100%",
          marginTop: "10%",
          textAlign: "left",
          fontSize: "13px",
        }}
      >
        {items.map((menu: MenuItemInteface) => {
          const { key, label, link, children, icon } = menu;
          return children.length > 0 ? (
            <SubMenu key={key} title={label} icon={icon}>
              {children.map((sub_menu) => (
                <Menu.Item key={sub_menu.key} icon={sub_menu.icon}>
                  <Link to={sub_menu.link}>{sub_menu.label}</Link>
                </Menu.Item>
              ))}
            </SubMenu>
          ) : (
            <Menu.Item key={key} icon={icon}>
              <Link to={link}>{label}</Link>
            </Menu.Item>
          );
        })}
      </Menu>
    </>
  );
};
