import { search } from "../../api/API";

export const useRevalidateUser = () => {
  const validate = async () => {
    var permissions = localStorage.getItem("permissions");
    var jwt = localStorage.getItem("jwt");

    if (!permissions) {
      return false;
    }

    if (!jwt) {
      return false;
    }

    const parsedJWT = JSON.parse(jwt);

    const { token } = parsedJWT;

    const response = await search(
      `${process.env.REACT_APP_AUTOMART_ADMIN_API}/authentications/tokens/${token}`,
      {},
      {}
    );

    const { data } = response.data;

    if (data.length === 0) {
      localStorage.clear();
      return false;
    }

    if (data[0].is_revoked === 1) {
      localStorage.clear();
      return false;
    }

    return true;
  };

  return { validate };
};
