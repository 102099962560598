import { Form, message } from "antd";
import { useAuthenticateUser } from "../hooks/auth/useAuthenticateUser";
import { useNavigate } from "react-router-dom";
import { LoginIndexComponent } from "../components/login/LoginIndexComponent";

export const LoginPage = () => {
  const [loginForm] = Form.useForm();
  const { login } = useAuthenticateUser();
  const navigate = useNavigate();

  const onSubmitForm = async () => {
    const { email_address, password } = await loginForm.validateFields();
    const isUserAuthenticated = await login(email_address, password);

    await message.loading("Validating Email and Password", 2);

    if (isUserAuthenticated) {
      await message.success("Successful Login!", 2);
      await message.loading("Preparing Admin Dashboard", 2);
      loginForm.resetFields();
      navigate("/dashboard");
      return;
    }

    await message.error("Invalid Email or Password, please try again", 3);
  };

  return (
    <LoginIndexComponent loginForm={loginForm} onSubmitForm={onSubmitForm} />
  );
};
