import { Tag, Space, Button } from "antd";
import { FilterChipsProps } from "../../helpers/interfaces/FilteringInterfaces";
import { CloseOutlined } from "@ant-design/icons";

export const FilterChipsComponent = ({
  chips,
  isShowClearFilters,
  onClickClearFilters,
}: FilterChipsProps) => {
  return (
    <Space size={[0, 8]} wrap>
      {chips.map((item) => (
        <Tag onClose={item.onClose}>{item.name}</Tag>
      ))}
      {isShowClearFilters || chips.length > 0 ? (
        <Button
          icon={<CloseOutlined />}
          type="link"
          size="small"
          onClick={onClickClearFilters}
        >
          Clear Filter
        </Button>
      ) : null}
    </Space>
  );
};
