import { PageHeaderComponent } from "../global/PageHeaderComponent";
import { PlatformFilteringComponent } from "./functionalities/PlatformFilteringComponent";
import { PlatformFunctionalityComponent } from "./functionalities/PlatformFunctionalityComponent";
import { CreatePlatformComponent } from "./modals/CreatePlatformComponent";
import { UpdatePlatformComponent } from "./modals/UpdatePlatformComponent";
import { PlatformTableComponent } from "./view/PlatformTableComponent";
import { ArchivePlatformComponent } from "./modals/ArchivePlatformComponent";

type PlatformIndexComponentProps = {
  handleChangeCreatePlatformModalStatus: () => void;
  createModalStatus: boolean;
  onClickCreatePlatform: () => void;
  createPlatformFormProps: any;
  updateModalStatus: boolean;
  onClickUpdatePlatform: () => void;
  updatePlatformFormProps: any;
  handleChangeUpdatePlatformModalStatus: () => void;
  platformsList: any;
  onPaginationChange: any;
  onSelectRow: any;
  isConfirmLoading: any;
  selectedRowKey: any;
  onSearch: any;
  searchPlatformFormProps: any;
  isDataFiltered: boolean;
  platformsTableMeta: any;
  resetSearch: () => void;
  handleChangeArchivePlatformModalStatus: () => void;
  archiveModalStatus: boolean;
  onClickArchivePlatform: () => void;
  archivePlatformFormProps: any;
  OnUpdateStatus: () => void;
  selectedRow: any;
  filterChips: Array<any>;
};

export const PlatformIndexComponent = ({
  handleChangeCreatePlatformModalStatus,
  createModalStatus,
  onClickCreatePlatform,
  createPlatformFormProps,
  updateModalStatus,
  onClickUpdatePlatform,
  updatePlatformFormProps,
  handleChangeUpdatePlatformModalStatus,
  platformsList,
  onPaginationChange,
  onSelectRow,
  isConfirmLoading,
  selectedRowKey,
  onSearch,
  searchPlatformFormProps,
  isDataFiltered,
  platformsTableMeta,
  resetSearch,
  handleChangeArchivePlatformModalStatus,
  archiveModalStatus,
  onClickArchivePlatform,
  archivePlatformFormProps,
  OnUpdateStatus,
  selectedRow,
  filterChips,
}: PlatformIndexComponentProps) => {
  return (
    <>
      <PageHeaderComponent
        level={3}
        title={"Platforms Management"}
        breadCrumbs={[{ path: "/platforms", breadcrumbName: "Platforms" }]}
      />
      <PlatformFilteringComponent
        onClickSearch={onSearch}
        form={searchPlatformFormProps}
        isDataFiltered={isDataFiltered}
        platformsTableMeta={platformsTableMeta}
        resetSearch={resetSearch}
        selectedRow={selectedRow}
        filterChips={filterChips}
      />
      <PlatformFunctionalityComponent
        onClickCreatePlatform={handleChangeCreatePlatformModalStatus}
        onClickUpdatePlatform={handleChangeUpdatePlatformModalStatus}
        onClickArchivePlatform={OnUpdateStatus}
        selectedRow={selectedRow}
      />
      <PlatformTableComponent
        tableProps={platformsList}
        onPaginationChange={onPaginationChange}
        onSelectRow={onSelectRow}
        selectedRowKey={selectedRowKey}
      />
      {/* Modal Components */}
      <CreatePlatformComponent
        isModalOpen={createModalStatus}
        onCancel={handleChangeCreatePlatformModalStatus}
        onOK={onClickCreatePlatform}
        header={<div style={{ marginTop: "7%" }} />}
        form={createPlatformFormProps}
        confirmLoading={isConfirmLoading}
      />
      <UpdatePlatformComponent
        isModalOpen={updateModalStatus}
        onCancel={handleChangeUpdatePlatformModalStatus}
        onOK={onClickUpdatePlatform}
        header={<div style={{ marginTop: "7%" }} />}
        form={updatePlatformFormProps}
        selectedRowKey={selectedRowKey}
        onSelectRow={onSelectRow}
      />
      <ArchivePlatformComponent
        isModalOpen={archiveModalStatus}
        onCancel={handleChangeArchivePlatformModalStatus}
        onOK={onClickArchivePlatform}
        header={<div style={{ marginTop: "7%" }} />}
        form={archivePlatformFormProps}
        confirmLoading={isConfirmLoading}
        selectedRowKey={selectedRowKey}
        onSelectRow={onSelectRow}
      />
    </>
  );
};
