import { message } from "antd";
import { UpdateAreaOfCoverage } from "../../api/UpdateAreaOfCoverage";
import { update } from "../../api/API";
import { useAPIResponse } from "../global/useApiResponse";
import { AreaOfCoveragesUpdatePayload } from "../../helpers/interfaces/AreaOfCoverageInterfaces";

export const useUpdateAreaOfCoverage = async (
  payload: Array<AreaOfCoveragesUpdatePayload>
) => {
  const executeProvidersAreaOfCoverageAPI = async () => {
    try {
      const localStorage = window.localStorage.getItem("jwt");
      const headers = { Authorization: "" };

      if (localStorage) {
        const parsedLocalStorage = JSON.parse(localStorage);
        headers["Authorization"] = parsedLocalStorage.token;
      }

      return await update(
        `${process.env.REACT_APP_API_URL}/providers/area-of-coverage/all`,
        payload,
        {
          headers: { ...headers },
        }
      );
    } catch (err: any) {
      if (err.request) return err.request;
      if (err.response) return err.response;
      return err.message;
    }
  };

  return useAPIResponse(await executeProvidersAreaOfCoverageAPI());
};
