import axios from "axios";
import { errorLogger } from "../helpers/functions/errorLogger";

export const fetch = async (url: string, params: any, headers: any) => {
  try {
    return await axios.get(url, { params, headers });
  } catch (err) {
    return errorLogger(err);
  }
};

export const create = async (url: string, payload: any, headers: any) => {
  try {
    return await axios.post(url, payload, headers);
  } catch (err) {
    return errorLogger(err);
  }
};

export const update = async (url: string, payload: any, headers: any) => {
  try {
    return await axios.put(url, payload, headers);
  } catch (err) {
    return errorLogger(err);
  }
};

export const search = async (url: string, params: any, headers: any) => {
  try {
    return await axios.get(url, { params, headers });
  } catch (err) {
    return errorLogger(err);
  }
};
