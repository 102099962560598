import { Input } from "antd";
import { ModalComponent } from "../../global/ModalComponent";

export const UpdateProviderTypesComponent = ({
  isModalOpen,
  onCancel,
  onOK,
  header,
  form,
}: any) => {
  const _form = {
    ...form,
    formItems: [
      {
        itemProp: { name: "provider_type_name", label: "Provider Type Name" },
        itemComponent: <Input placeholder="Input Provider Type Name" />,
      },
    ],
  };

  return (
    <ModalComponent
      isModalOpen={isModalOpen}
      onCancel={onCancel}
      onOK={onOK}
      header={header}
      form={_form}
      title={"Update Provider Type"}
    />
  );
};
