import { PageHeaderComponent } from "../global/PageHeaderComponent";
import { ProviderTypesFilteringComponent } from "./functionalities/ProviderTypesFilteringComponent";
import { ProviderTypesFunctionalityComponent } from "./functionalities/ProviderTypesFunctionalityComponent";
import { CreateProviderTypesComponent } from "./modals/CreateProviderTypesComponent";
import { UpdateProviderTypesComponent } from "./modals/UpdateProviderTypesComponent";
import { ProviderTypesTableComponent } from "./view/ProviderTypesTableComponent";
import { ArchiveProviderTypesComponent } from "./modals/ArchiveProviderTypesComponent";

type ProviderTypesIndexComponentProps = {
  handleChangeCreateProviderTypesModalStatus: () => void;
  providerTypescreateModalStatus: boolean;
  onClickCreateProviderTypes: () => void;
  createProviderTypesFormProps: any;
  providerTypesupdateModalStatus: boolean;
  onClickUpdateProviderTypes: () => void;
  updateProviderTypesFormProps: any;
  handleChangeUpdateProviderTypesModalStatus: () => void;
  providerTypesList: any;
  onPaginationChange: any;
  onSelectRow: any;
  isConfirmLoading: any;
  selectedRowKey: any;
  onSearch: any;
  searchProviderTypesFormProps: any;
  isDataFiltered: boolean;
  providerTypesTableMeta: any;
  resetSearch: () => void;
  handleChangeArchiveProviderTypesModalStatus: () => void;
  providerTypesarchiveModalStatus: boolean;
  onClickArchivePlatform: () => void;
  archiveProviderTypesFormProps: any;
  OnUpdateStatus: () => void;
  selectedRow: any;
  filterChips: Array<any>;
};

export const ProviderTypesIndexComponent = ({
  handleChangeCreateProviderTypesModalStatus,
  providerTypescreateModalStatus,
  onClickCreateProviderTypes,
  createProviderTypesFormProps,
  providerTypesupdateModalStatus,
  onClickUpdateProviderTypes,
  updateProviderTypesFormProps,
  handleChangeUpdateProviderTypesModalStatus,
  providerTypesList,
  onPaginationChange,
  onSelectRow,
  isConfirmLoading,
  selectedRowKey,
  onSearch,
  searchProviderTypesFormProps,
  isDataFiltered,
  providerTypesTableMeta,
  resetSearch,
  handleChangeArchiveProviderTypesModalStatus,
  providerTypesarchiveModalStatus,
  onClickArchivePlatform,
  archiveProviderTypesFormProps,
  OnUpdateStatus,
  selectedRow,
  filterChips,
}: ProviderTypesIndexComponentProps) => {
  return (
    <>
      <PageHeaderComponent
        level={3}
        title={"Provider Types Management"}
        breadCrumbs={[
          {
            path: "/providers",
            breadcrumbName: "Providers",
          },
          {
            path: "/provider-types",
            breadcrumbName: "Provider Types",
          },
        ]}
      />
      <ProviderTypesFilteringComponent
        onClickSearch={onSearch}
        form={searchProviderTypesFormProps}
        isDataFiltered={isDataFiltered}
        providerTypesTableMeta={providerTypesTableMeta}
        resetSearch={resetSearch}
        selectedRow={selectedRow}
        filterChips={filterChips}
      />
      <ProviderTypesFunctionalityComponent
        onClickCreatePlatform={handleChangeCreateProviderTypesModalStatus}
        onClickUpdatePlatform={handleChangeUpdateProviderTypesModalStatus}
        onClickArchivePlatform={OnUpdateStatus}
        selectedRow={selectedRow}
      />
      <ProviderTypesTableComponent
        tableProps={providerTypesList}
        onPaginationChange={onPaginationChange}
        onSelectRow={onSelectRow}
        selectedRowKey={selectedRowKey}
      />
      {/* Modal Components */}
      <CreateProviderTypesComponent
        isModalOpen={providerTypescreateModalStatus}
        onCancel={handleChangeCreateProviderTypesModalStatus}
        onOK={onClickCreateProviderTypes}
        header={<div style={{ marginTop: "7%" }} />}
        form={createProviderTypesFormProps}
        confirmLoading={isConfirmLoading}
      />
      <UpdateProviderTypesComponent
        isModalOpen={providerTypesupdateModalStatus}
        onCancel={handleChangeUpdateProviderTypesModalStatus}
        onOK={onClickUpdateProviderTypes}
        header={<div style={{ marginTop: "7%" }} />}
        form={updateProviderTypesFormProps}
        selectedRowKey={selectedRowKey}
        onSelectRow={onSelectRow}
      />
      <ArchiveProviderTypesComponent
        isModalOpen={providerTypesarchiveModalStatus}
        onCancel={handleChangeArchiveProviderTypesModalStatus}
        onOK={onClickArchivePlatform}
        header={<div style={{ marginTop: "7%" }} />}
        form={archiveProviderTypesFormProps}
        confirmLoading={isConfirmLoading}
        selectedRowKey={selectedRowKey}
        onSelectRow={onSelectRow}
      />
    </>
  );
};
