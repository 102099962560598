import { update } from "./API";

export const UpdatePlatforms = async (platform_id: number, payload: any) => {
  try {
    const localStorage = window.localStorage.getItem("jwt");

    const headers = { Authorization: "" };

    if (localStorage) {
      const parsedLocalStorage = JSON.parse(localStorage);
      headers["Authorization"] = parsedLocalStorage.token;
    }

    return await update(
      `${process.env.REACT_APP_API_URL}/platforms/${platform_id}`,
      payload,
      {
        headers: { ...headers },
      }
    );
  } catch (err: any) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};
