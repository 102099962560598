import { useState, useEffect } from "react";

export const useReturnGetRoleInformation = () => {
  const [userRole, setUserRoleInformation] = useState(null);
  const genInfo = JSON.parse(JSON.stringify(localStorage.getItem("role")));

  useEffect(() => {
    if (genInfo) {
      setUserRoleInformation(genInfo);
    }
  }, []);

  return userRole;
};
