interface PageHeaderProps {
  level: 1 | 2 | 3 | 4 | 5;
  title: string;
  breadCrumbs: Array<{
    path: string;
    breadcrumbName: string;
  }>;
}

const returnBreadCrumbsProps = () => {
  const props: PageHeaderProps = {
    level: 3,
    title: "Area of Coverages",
    breadCrumbs: [
      {
        path: "/providers",
        breadcrumbName: "Providers",
      },
      {
        path: "/providers/area-of-coverage",
        breadcrumbName: "Area of Coverage",
      },
    ],
  };

  return props;
};

export default returnBreadCrumbsProps;
