import { UserInformationInterface } from "../../interfaces/UserInterfaces";

export const transformUserQueryResponse = (data: any) => {
  if (!data) {
    return {} as UserInformationInterface;
  }

  const { address, mobile, email, phone, first_name, middle_name, last_name } =
    data;

  var fullName = "";

  if (first_name) {
    fullName += `${first_name} `;
  }

  if (middle_name) {
    fullName += `${middle_name} `;
  }

  if (last_name) {
    fullName += `${last_name} `;
  }

  return {
    address,
    mobile,
    email,
    phone,
    fullName,
  } as UserInformationInterface;
};
