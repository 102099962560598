import { Avatar, Layout, Space, Popover } from "antd";
import { AntDesignOutlined } from "@ant-design/icons";
import { SiderNavigationComponent } from "../../components/main/sider/SiderNavigationComponent";
import { SiderTitleComponent } from "../../components/main/sider/SiderTitleComponent";
import { ProfileDrawerComponent } from "../../components/main/profile/ProfileDrawerComponent";
import { PopOverTitleComponent } from "../../components/main/pop_over/PopOverTitleComponent";
import { PopOverContentComponent } from "../../components/main/pop_over/PopOverContentComponent";

const { Header, Content, Footer, Sider } = Layout;

type AppLayoutProps = {
  contextHolder: any;
  loggedInUserInfo: any;
  onClickViewProfile: () => void;
  openDrawerStatus: boolean;
  onClickLogout: () => void;
  generalInfo: any;
  roleInfo: any;
  userPopDownStatus: boolean;
  onHandleHoverChange: () => void;
  route: any;
};

export const AppLayoutIndexComponent = ({
  contextHolder,
  loggedInUserInfo,
  onClickViewProfile,
  openDrawerStatus,
  onClickLogout,
  generalInfo,
  roleInfo,
  userPopDownStatus,
  onHandleHoverChange,
  route,
}: AppLayoutProps) => {
  return (
    <Layout>
      {contextHolder}
      <ProfileDrawerComponent
        loggedInUserInfo={loggedInUserInfo}
        onClickViewProfile={onClickViewProfile}
        openDrawerStatus={openDrawerStatus}
        key={"profile-drawer"}
      />
      <Sider
        theme="light"
        breakpoint="lg"
        collapsible={true}
        collapsedWidth={0}
        style={{
          left: 0,
          height: "100vh",
          boxShadow: "-0.3px 20px 5px grey",
        }}
      >
        <SiderTitleComponent />
        <SiderNavigationComponent />
      </Sider>
      <Layout>
        <Header style={{ backgroundColor: "white", height: "8%" }}>
          <div style={{ textAlign: "right" }}>
            <Space>
              <Popover
                content={
                  <PopOverContentComponent
                    onClickViewProfile={onClickViewProfile}
                    onClickLogout={onClickLogout}
                  />
                }
                title={
                  <PopOverTitleComponent
                    generalInfo={generalInfo}
                    roleInfo={roleInfo}
                  />
                }
                trigger="hover"
                placement="bottomLeft"
                open={userPopDownStatus}
                onOpenChange={onHandleHoverChange}
              >
                <Avatar
                  shape="square"
                  size={{ xs: 36, sm: 36, md: 36, lg: 36, xl: 36, xxl: 40 }}
                  gap={10}
                  icon={<AntDesignOutlined />}
                />
              </Popover>
            </Space>
          </div>
        </Header>
        <Content style={{ margin: "24px 16px 0" }}>
          <div
            style={{
              padding: "0px 24px 24px 24px",
              minHeight: "100%",
            }}
          >
            <route.component />
          </div>
        </Content>
        <Footer style={{ textAlign: "center" }}>Installment Admin 3.0</Footer>
      </Layout>
    </Layout>
  );
};
