import { message } from "antd";
import { UpdatePlatforms } from "../../api/UpdatePlatforms";
import { useAPIResponse } from "../global/useApiResponse";

export interface updateData {
  platform_name?: string;
}

export const useUpdatePlatform = async (platform_id: number, payload: any) => {
  const loadingMessage =
    payload.is_active === 0 ? "Archiving Platform" : "Updating Platform";
  await message.loading(loadingMessage + "..", 2);

  const response = await UpdatePlatforms(platform_id, payload);

  return useAPIResponse(response);
};
