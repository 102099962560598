import { Input } from "antd";
import { ModalComponent } from "../../global/ModalComponent";

export const UpdatePlatformComponent = ({
  isModalOpen,
  onCancel,
  onOK,
  header,
  form,
}: any) => {
  const _form = {
    ...form,
    formItems: [
      {
        itemProp: { name: "platform_name", label: "Platform Name" },
        itemComponent: <Input placeholder="Input Platform Name" />,
      },
    ],
  };

  return (
    <ModalComponent
      isModalOpen={isModalOpen}
      onCancel={onCancel}
      onOK={onOK}
      header={header}
      form={_form}
      title={"Update Platform"}
    />
  );
};
