import { search } from "../../api/API";
import { transformUserQueryResponse } from "../../helpers/transformers/user/transformUserQueryResponse";

export const useQueryUser = () => {
  const queryUser = async (email_address: string) => {
    var params = { email_address };
    var url = `${process.env.REACT_APP_AUTOMART_ADMIN_API}/users/query`;
    var jwt = localStorage.getItem("jwt");

    if (jwt) {
      const { token } = JSON.parse(jwt);
      const headers = { Authorization: token };
      const response = await search(url, params, headers);
      return transformUserQueryResponse(response.data.data);
    }

    return transformUserQueryResponse(null);
  };

  return { queryUser };
};
