import { useState, useEffect } from "react";
import { FetchProviderTypes } from "../../api/FetchProviderTypes";

export interface dataType {
  meta: {
    page?: number;
    limit?: number;
    total?: number;
    current_page?: number;
    last_page?: number;
    first_page?: number;
    first_page_url?: string;
    last_page_url?: string;
    next_page_url?: string;
    previous_page_url?: any;
  };
  data: data[];
}

export interface data {
  data: {
    provider_type_name: string;
    created_by: any;
    created_at: any;
  };
}

const default_page_size = 10;

const _data: dataType = {
  meta: {
    page: 1,
    limit: default_page_size,
  },
  data: [],
};

export const useFetchProviderTypes = (query: {
  page: number;
  limit: number;
  search_value?: string;
}) => {
  const [dataInfo, setDataInfo] = useState(_data);

  useEffect(() => {
    const fetchData = async () => {
      const result = await FetchProviderTypes(query);

      if (result.data.data) {
        for (const item of result.data.data) {
          item["key"] = item.provider_type_id;
        }
      }

      setDataInfo(result);
    };

    fetchData();
  }, [query]);

  return dataInfo;
};
