import { Form } from "antd";

const GetPlatformProps = () => {
  const [createPlatform] = Form.useForm();
  const [updatePlatform] = Form.useForm();
  const [searchPlatformForm] = Form.useForm();
  const [archivePlatform] = Form.useForm();

  const createPlatformFormProps = {
    formMainProps: {
      form: createPlatform,
    },
  };

  const updatePlatformFormProps = {
    formMainProps: {
      form: updatePlatform,
    },
  };

  const searchPlatformFormProps = {
    formMainProps: {
      form: searchPlatformForm,
    },
  };

  const archivePlatformFormProps = {
    formMainProps: {
      form: archivePlatform,
    },
  };

  return {
    createPlatformFormProps,
    updatePlatformFormProps,
    searchPlatformFormProps,
    archivePlatformFormProps,
    createPlatform,
    updatePlatform,
    searchPlatformForm,
    archivePlatform,
  };
};

export default GetPlatformProps;
