import { useState } from "react";

export const useChangeUpdateAreaOfCoverageModalStatus = () => {
  const [updateAreaOfCoverageModalStatus, setUpdateAreaOfCoverageModalStatus] =
    useState(false);

  let onChangeUpdateModalStatus = () => {
    const status_ = updateAreaOfCoverageModalStatus ? false : true;
    setUpdateAreaOfCoverageModalStatus(status_);
  };

  return {
    onChangeUpdateModalStatus,
    updateAreaOfCoverageModalStatus,
  };
};
