import { notification } from "antd";

export const useResponseProviderTypes = async (response: any) => {
  if (response.status === 200 || response.status === 201) {
    notification.success({
      message: response.data.message,
      duration: 2,
    });
    return true;
  } else if (response.status === 409) {
    notification.error({
      message: "Provider type already exists.",
      duration: 2,
    });
    return false;
  } else {
    notification.error({
      message: response.data.message || "An error occurred.",
      duration: 2,
    });
    return false;
  }
};
