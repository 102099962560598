import { PageHeaderComponent } from "../global/PageHeaderComponent";
import { ProvidersAreaOfCoverageFilteringComponent } from "./functionalities/ProvidersAreaOfCoverageFilteringComponent";
import { ProvidersAreaOfCoverageFunctionalityComponent } from "./functionalities/ProvidersAreaOfCoverageFunctionalityComponent";
import { UpdateProvidersAreaOfCoverageComponent } from "./modals/UpdateProvidersAreaOfCoverageComponent";
import { ProvidersAreaOfCoverageTableComponent } from "./view/ProvidersAreaOfCoverageTableComponent";
import returnBreadCrumbsProps from "../../helpers/functions/providers_area_of_coverage/returnBreadCrumbsProps";

type ProvidersAreaOfCoverageIndexComponentProps = {
  updateAreaOfCoverageModalStatus: boolean;
  onClickUpdateAreaOfCoverage: () => void;
  updateAreaOfCoverageFormProps: any;
  handleChangeUpdateAreaOfCoverageModalStatus: () => void;
  areaOfCoverageList: any;
  onPaginationChange: any;
};

export const ProvidersAreaOfCoverageIndexComponent = ({
  updateAreaOfCoverageModalStatus,
  onClickUpdateAreaOfCoverage,
  updateAreaOfCoverageFormProps,
  handleChangeUpdateAreaOfCoverageModalStatus,
  areaOfCoverageList,
  onPaginationChange,
}: ProvidersAreaOfCoverageIndexComponentProps) => {
  const pageHeaderProps = returnBreadCrumbsProps();

  return (
    <>
      <PageHeaderComponent {...pageHeaderProps} />
      <ProvidersAreaOfCoverageFilteringComponent />
      <ProvidersAreaOfCoverageFunctionalityComponent
        onClickUpdateAreaOfCoverage={onClickUpdateAreaOfCoverage}
        handleChangeUpdateAreaOfCoverageModalStatus={
          handleChangeUpdateAreaOfCoverageModalStatus
        }
      />
      <ProvidersAreaOfCoverageTableComponent
        tableProps={areaOfCoverageList}
        onPaginationChange={onPaginationChange}
      />
      {/* Modal Components */}
      <UpdateProvidersAreaOfCoverageComponent
        isModalOpen={updateAreaOfCoverageModalStatus}
        onCancel={handleChangeUpdateAreaOfCoverageModalStatus}
        onOK={onClickUpdateAreaOfCoverage}
        form={updateAreaOfCoverageFormProps}
      />
    </>
  );
};
