import { useEffect, useState } from "react";
import { message, Modal, notification } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { ProviderTypesIndexComponent } from "../components/providerTypes/ProviderTypesIndexComponent";
import { useChangeUpdateProviderTypesModalStatus } from "../hooks/provider_types/useChangeUpdateProviderTypesModalStatus";
import { useChangeCreateProviderTypesModalStatus } from "../hooks/provider_types/useChangeCreateProviderTypesModalStatus";
import { useChangeArchiveProviderTypesModalStatus } from "../hooks/provider_types/useChangeArchiveProviderTypesModalStatus";
import { useFetchProviderTypes } from "../hooks/provider_types/useFetchProviderTypes";
import { useCreateProviderTypes } from "../hooks/provider_types/useCreateProviderTypes";
import { useUpdateProviderTypes } from "../hooks/provider_types/useUpdateProviderTypes";
import GetProviderTypesProps from "../helpers/functions/getProviderTypesFormProps";

/** INTERFACE FOR FILTERCHIPS */

interface filterChips {
  name?: any;
  onClose?: () => void;
}

const chipsDefaultValue: Array<filterChips> = [];

export const ProviderTypesPage = () => {
  const [providerTypesTableMeta, setProviderTypesTableMeta] = useState({
    page: 1,
    limit: 10,
    query_search_value: "",
  });

  const [filterChips, setFilterChips] = useState(chipsDefaultValue);

  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowKey, setSelectedRowKey] = useState(0);
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  const [isDataFiltered, setIsDataFiltered] = useState(false);

  const createProviderTypesModal = useChangeCreateProviderTypesModalStatus();
  const updateProviderTypesModal = useChangeUpdateProviderTypesModalStatus();
  const archiveProviderTypesModal = useChangeArchiveProviderTypesModalStatus();
  const {
    archiveProviderTypesFormProps,
    createProviderTypesFormProps,
    searchProviderTypesFormProps,
    updateProviderTypesFormProps,
    archiveProviderTypes,
    createProviderTypes,
    searchProviderTypesForm,
    updateProviderTypes,
  } = GetProviderTypesProps();

  let providerTypesList = useFetchProviderTypes(providerTypesTableMeta);
  const providerTypesCreateData = useCreateProviderTypes;
  const providerTypesUpdateData = useUpdateProviderTypes;

  const onPaginationChange = (page: any) => {
    setProviderTypesTableMeta({ ...providerTypesTableMeta, page: page });
  };

  /*  for selecting rows in table using radio button */
  const onSelectRow = (
    selectedRowIds: Array<number>,
    selectedRowInfos: Array<any>
  ) => {
    setSelectedRow(selectedRowInfos[0]);
    setSelectedRowKey(selectedRowIds[0]);
    setIsDataFiltered(true);
    setFilterChips([
      {
        name: `Selected: ${selectedRowInfos[0].provider_type_name}`,
        onClose: () => {
          setSelectedRow(null);
          setSelectedRowKey(0);
          setIsDataFiltered(false);
          setFilterChips([]);
        },
      },
    ]);
  };

  /* useEffect for update function */
  useEffect(() => {
    if (selectedRow) {
      updateProviderTypes?.setFieldsValue(selectedRow);
    }
  }, [selectedRow]);

  const onRemoveAllSearches = () => {
    createProviderTypes.resetFields();
    updateProviderTypes.resetFields();
    archiveProviderTypes.resetFields();
  };

  /** FUNCTION FOR CREATE */

  const onClickCreateProviderTypes = async () => {
    setIsConfirmLoading(true);
    const isEmptyFields = Object.values(
      createProviderTypes.getFieldsValue()
    ).some((value) => !value);

    if (isEmptyFields) {
      notification.error({
        message: "The provider type name field cannot be blank.",
      });
      return;
    }
    const values = await createProviderTypes.validateFields();

    // check if the userInfo get the local storage users id
    const userInfo = localStorage.getItem("userInfo");

    if (userInfo) {
      const { id } = JSON.parse(userInfo);
      values["created_by"] = id ? id : 0;
    }

    // check if the provider type name has white space
    const providerTypesName = values["provider_type_name"];
    const hasNonWhitespaceCharacters = providerTypesName.trim().length > 0;
    if (hasNonWhitespaceCharacters) {
      const isSuccess = await providerTypesCreateData(values);
      if (isSuccess) {
        createProviderTypesModal.onChange();
        onRemoveAllSearches();
        setProviderTypesTableMeta({ ...providerTypesTableMeta, limit: 10 });
      }
    } else {
      message.error("Please input a valid provider type name");
    }
    setIsConfirmLoading(false);
  };

  /** FUNCTION FOR UPDATE */

  const onClickUpdateProviderTypes = async () => {
    const isEmptyFields = Object.values(
      updateProviderTypes.getFieldsValue()
    ).some((value) => !value);

    if (isEmptyFields) {
      notification.error({
        message: "The provider type name field cannot be blank.",
      });
      return;
    }
    const values = await updateProviderTypes.validateFields();

    const provider_type_name = values.provider_type_name;

    var isOnlyWhiteSpace: boolean = false;

    const updateForProviderTypeChar = provider_type_name.split();

    for (const updateChar of updateForProviderTypeChar) {
      isOnlyWhiteSpace = updateChar === " " ? true : false;
    }

    if (isOnlyWhiteSpace) {
      notification.error({
        message: "Update Provider type cannot be blank.",
      });
      return;
    }

    const isSuccess = await providerTypesUpdateData(selectedRowKey, values);
    if (isSuccess) {
      updateProviderTypesModal.onChange();
      onRemoveAllSearches();
      setProviderTypesTableMeta({ ...providerTypesTableMeta, limit: 10 });
      setSelectedRowKey(0);
      setSelectedRow(null);
      setIsDataFiltered(false);
    }
  };

  /** FUNCTION FOR ARCHIVE */
  const { confirm } = Modal;

  const OnUpdateStatus = async () => {
    await message.info("To proceed kindly provide reason for archiving", 2);
    archiveProviderTypesModal.onChange();
  };

  const onClickArchivePlatform = async () => {
    confirm({
      centered: true,
      title: "Archive Provider Type",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure you want to archive this provider type?",
      async onOk() {
        // Check if form fields are not empty
        const isEmptyFields = Object.values(
          archiveProviderTypes.getFieldsValue()
        ).some((value) => !value);

        if (isEmptyFields) {
          notification.error({
            message:
              "Please provide a reason for archiving this provider type.",
          });
          return;
        }

        const values = await archiveProviderTypes.validateFields();
        const payload = { is_active: 0 };
        const reason_for_archiving = values.reason_for_archiving;

        var isOnlyWhiteSpace: boolean = false;

        const reasonForArchvingChar = reason_for_archiving.split();

        for (const reasonChar of reasonForArchvingChar) {
          isOnlyWhiteSpace = reasonChar === " " ? true : false;
        }

        // Check if the reason_for_archiving field contains only whitespace or is empty

        if (isOnlyWhiteSpace) {
          notification.error({
            message: "Reason for archiving cannot be empty.",
          });
          return;
        }

        const isSuccess = await providerTypesUpdateData(
          selectedRowKey,
          payload
        );
        if (isSuccess) {
          if (payload.is_active === 0) {
            notification.destroy();
            notification.success({
              message: "Provider Type has been archived!",
            });
          }

          onRemoveAllSearches();
          setProviderTypesTableMeta({ ...providerTypesTableMeta, limit: 10 });
          setSelectedRowKey(0);
          setSelectedRow(null);
          setIsDataFiltered(false);
          archiveProviderTypesModal.onChange();
        }
      },
      onCancel() {
        return;
      },
    });
  };

  /** FUNCTION FOR SEARCH */

  const onSearch = async () => {
    const values = await searchProviderTypesForm.validateFields();
    if (values) {
      setIsDataFiltered(true);
      setProviderTypesTableMeta((previousState) => {
        return {
          ...previousState,
          query_search_value: values.query_search_value,
        };
      });
      setFilterChips((prevState) => {
        return [
          ...prevState,
          { name: values.query_search_value, onClose: () => {} },
        ];
      });
    }
  };

  const resetSearch = () => {
    setProviderTypesTableMeta((previousState) => {
      return {
        ...previousState,
        query_search_value: "",
      };
    });

    searchProviderTypesForm.resetFields();
    setIsDataFiltered(false);
    setSelectedRowKey(0);
    setSelectedRow(null);
    setFilterChips([]);
  };

  // compose Props

  const providerTypesIndexProps = {
    providerTypescreateModalStatus: createProviderTypesModal.status,
    providerTypesupdateModalStatus: updateProviderTypesModal.status,
    providerTypesarchiveModalStatus: archiveProviderTypesModal.status,
    handleChangeCreateProviderTypesModalStatus:
      createProviderTypesModal.onChange,
    handleChangeUpdateProviderTypesModalStatus:
      updateProviderTypesModal.onChange,
    handleChangeArchiveProviderTypesModalStatus:
      archiveProviderTypesModal.onChange,
    createProviderTypesFormProps,
    updateProviderTypesFormProps,
    searchProviderTypesFormProps,
    archiveProviderTypesFormProps,
    onClickUpdateProviderTypes,
    onClickCreateProviderTypes,
    onClickArchivePlatform,
    providerTypesList,
    onPaginationChange,
    onSelectRow,
    isConfirmLoading,
    selectedRowKey,
    onSearch,
    isDataFiltered,
    providerTypesTableMeta,
    resetSearch,
    OnUpdateStatus,
    selectedRow,
    filterChips,
  };

  return <ProviderTypesIndexComponent {...providerTypesIndexProps} />;
};
