import { message } from "antd";
import { CreatePlatforms } from "../../api/CreatePlatforms";
import { useAPIResponse } from "../global/useApiResponse";

export const useCreatePlatform = async (payload: any) => {
  await message.loading("Creating Platform..", 2);
  const response = await CreatePlatforms(payload);

  return useAPIResponse(response);
};
