import { ReactNode } from "react";
import { notification } from "antd";

import type { NotificationPlacement } from "antd/es/notification/interface";

type NotificationType = "success" | "info" | "warning" | "error";

interface Notification {
  type: NotificationType;
  message: string;
  description: string;
  placement: NotificationPlacement;
  icon?: ReactNode;
  duration?: number;
  onClose?: any;
}

export const useNotification = () => {
  const [api, contextHolder] = notification.useNotification();

  const show = async ({
    type,
    message,
    description,
    placement,
    icon,
    duration,
    onClose,
  }: Notification) => {
    api.open({
      type,
      message,
      description,
      placement,
      icon,
      duration,
      onClose,
    });
  };

  return { show, contextHolder };
};
