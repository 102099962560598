import { Result } from "antd";
import React from "react";

export const DashboardPage = () => {
  return (
    <div style={{ padding: "8%" }}>
      <Result
        status="404"
        title="Under Development"
        subTitle="Sorry, the page you visited is under development."
      />
    </div>
  );
};
