import { Input } from "antd";
import { ModalComponent } from "../../global/ModalComponent";

export const ArchiveProviderTypesComponent = ({
  isModalOpen,
  onCancel,
  onOK,
  header,
  form,
}: any) => {
  const _form = {
    ...form,
    formItems: [
      {
        itemProp: {
          name: "reason_for_archiving",
          label: "Reason for archiving",
          rules: [
            {
              required: true,
              message: "Please input reason for archiving!",
            },
          ],
        },
        itemComponent: <Input placeholder="Input Reason for archiving" />,
      },
    ],
  };

  return (
    <ModalComponent
      isModalOpen={isModalOpen}
      onCancel={onCancel}
      onOK={onOK}
      header={header}
      form={_form}
      title={"Archive Provider Type"}
    />
  );
};
