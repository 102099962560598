import { create } from "./API";

export const CreatePlatforms = async (payload: any) => {
  try {
    const localStorage = window.localStorage.getItem("jwt");

    const headers = { Authorization: "" };

    if (localStorage) {
      const parsedLocalStorage = JSON.parse(localStorage);
      headers["Authorization"] = parsedLocalStorage.token;
    }

    return await create(`${process.env.REACT_APP_API_URL}/platforms`, payload, {
      headers: { ...headers },
    });
  } catch (err: any) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};
