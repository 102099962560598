import { Button, Space } from "antd";
import { FormOutlined, EditOutlined, UndoOutlined } from "@ant-design/icons";
export const PlatformFunctionalityComponent = ({
  onClickCreatePlatform,
  onClickUpdatePlatform,
  onClickArchivePlatform,
  selectedRow,
}: any) => {
  return (
    <div style={{ textAlign: "right", marginTop: "2%" }}>
      <Space size={10}>
        <Button
          type="primary"
          icon={<FormOutlined />}
          onClick={onClickCreatePlatform}
        >
          Create Platform
        </Button>
        {selectedRow && (
          <>
            <Button
              type="default"
              icon={<EditOutlined />}
              onClick={onClickUpdatePlatform}
            >
              Update Platform
            </Button>
            <Button
              type="primary"
              danger
              icon={<UndoOutlined />}
              onClick={onClickArchivePlatform}
            >
              Archive Platform
            </Button>
          </>
        )}
      </Space>
    </div>
  );
};
