import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { LoginPage } from "../../../pages/LoginPage";
import { AppLayout } from "../../../pages/AppLayout";
import { OptionsPage } from "../../../pages/OptionsPage";
import { PlatformsPage } from "../../../pages/PlatformsPage";
import { ProvidersAreaOfCoveragePage } from "../../../pages/ProvidersAreaOfCoveragePage";
import { ProviderTypesPage } from "../../../pages/ProviderTypesPage";
import { DashboardPage } from "../../../pages/DashboardPage";

export const RoutesComponent = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/platforms"
          element={<AppLayout route={{ component: PlatformsPage }} />}
        />
        <Route
          path="/providers/area-of-coverage"
          element={
            <AppLayout route={{ component: ProvidersAreaOfCoveragePage }} />
          }
        />
        <Route
          path="/provider-types"
          element={<AppLayout route={{ component: ProviderTypesPage }} />}
        />
        <Route
          path="/options"
          element={<AppLayout route={{ component: OptionsPage }} />}
        />
        <Route
          path="/dashboard"
          element={<AppLayout route={{ component: DashboardPage }} />}
        />
        <Route path="*" element={<Navigate to="/dashboard" replace={true} />} />
      </Routes>
    </BrowserRouter>
  );
};
