import { Button, Space } from "antd";
import { EditOutlined } from "@ant-design/icons";

export const ProvidersAreaOfCoverageFunctionalityComponent = ({
  onClickUpdateAreaOfCoverage,
}: any) => {
  return (
    <div style={{ textAlign: "right", marginTop: "2%" }}>
      <Space size={10}>
        <Button
          type="default"
          icon={<EditOutlined />}
          onClick={onClickUpdateAreaOfCoverage}
        >
          Update Area of Coverage
        </Button>
      </Space>
    </div>
  );
};
