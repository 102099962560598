import { Form, Input, Button } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

type LoginFormProps = {
  form: any;
  onSubmitForm: () => void;
};

export const LoginFormComponent = ({ form, onSubmitForm }: LoginFormProps) => {
  return (
    <Form form={form} style={{ width: "40%" }}>
      <div
        style={{
          fontWeight: 400,
          marginBottom: "5%",
          fontSize: "large",
        }}
      >
        Installment Admin Dashboard 3.0
      </div>
      <Form.Item
        name="email_address"
        rules={[
          {
            required: true,
            message: "Please input your email address!",
          },
        ]}
      >
        <Input placeholder="Input Email Address" prefix={<UserOutlined />} />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: "Please input your password!" }]}
      >
        <Input.Password
          prefix={<LockOutlined />}
          placeholder="Input Password"
        />
      </Form.Item>
      <Form.Item>
        <Button type="link">Forgot Password (?)</Button>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" block onClick={onSubmitForm}>
          Login
        </Button>
      </Form.Item>
    </Form>
  );
};
