import { Drawer, Space, Avatar, Descriptions, Divider, Button } from "antd";
import {
  AntDesignOutlined,
  EditOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { UserInformationInterface } from "../../../helpers/interfaces/UserInterfaces";

type ProfileDrawerProps = {
  openDrawerStatus: boolean;
  onClickViewProfile: () => void;
  loggedInUserInfo: UserInformationInterface;
};

export const ProfileDrawerComponent = ({
  openDrawerStatus,
  onClickViewProfile,
  loggedInUserInfo,
}: ProfileDrawerProps) => {
  return (
    <Drawer
      title="User Profile"
      placement={"right"}
      width={430}
      open={openDrawerStatus}
      onClose={onClickViewProfile}
    >
      <div style={{ paddingTop: "2%", paddingBottom: "1%" }}>
        <Space direction="horizontal" align={"center"} size={30}>
          <Avatar
            shape="square"
            size={{ xs: 85, sm: 90, md: 90, lg: 90, xl: 110, xxl: 110 }}
            gap={10}
            icon={<AntDesignOutlined />}
          />
          <Descriptions
            size={"small"}
            bordered={false}
            style={{ borderSpacing: "0 0px" }}
          >
            <Descriptions.Item label="Name" span={3}>
              {loggedInUserInfo.fullName ? loggedInUserInfo.fullName : "N/A"}
            </Descriptions.Item>
            <Descriptions.Item label="Role" span={3}>
              {loggedInUserInfo.roleName ? loggedInUserInfo.roleName : "N/A"}
            </Descriptions.Item>
            <Descriptions.Item label="Email" span={3}>
              {loggedInUserInfo.email ? loggedInUserInfo.email : "N/A"}
            </Descriptions.Item>
            <Descriptions.Item label="Mobile" span={3}>
              {loggedInUserInfo.mobile ? loggedInUserInfo.mobile : "N/A"}
            </Descriptions.Item>
            <Descriptions.Item label="Phone" span={3}>
              {loggedInUserInfo.phone ? loggedInUserInfo.phone : "N/A"}
            </Descriptions.Item>
          </Descriptions>
        </Space>
      </div>
      <Divider />
      <div>
        <Button icon={<EditOutlined />} type="default" block>
          Update Information
        </Button>
        <Button
          icon={<LogoutOutlined />}
          danger
          block
          style={{ marginTop: "2%" }}
        >
          Logout
        </Button>
      </div>
    </Drawer>
  );
};
