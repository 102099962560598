import { Form } from "antd";
import { ProvidersAreaOfCoverageIndexComponent } from "../components/ProvidersAreaOfCoverage/ProvidersAreaOfCoverageIndexComponent";
import { useChangeUpdateAreaOfCoverageModalStatus } from "../hooks/providers_are_of_coverages/useChangeUpdateAreaOfCoverageModalStatus";
import { useState } from "react";
import { useFetchAreaOfCoverages } from "../hooks/providers_are_of_coverages/useFetchAreaOfCoverage";
import { useUpdateAreaOfCoverage } from "../hooks/providers_are_of_coverages/useUpdateAreaOfCoverage";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { providers_area_of_coverage } from "../helpers/statics/providers_providers_are_of_coverage";

export const ProvidersAreaOfCoveragePage = () => {
  const [areaOfCoveragesTableMeta, setareaOfCoveragesTableMeta] = useState({
    page: 1,
    limit: 10,
    query_search_value: "",
  });

  let areaOfCoverageList = useFetchAreaOfCoverages(areaOfCoveragesTableMeta);

  const updateAreaOfCoverages = useUpdateAreaOfCoverage;

  const onPaginationChange = (page: any) => {
    setareaOfCoveragesTableMeta({ ...areaOfCoveragesTableMeta, page: page });
  };

  const { onChangeUpdateModalStatus, updateAreaOfCoverageModalStatus } =
    useChangeUpdateAreaOfCoverageModalStatus();

  const [updateAreaOfCoverage] = Form.useForm();

  const updateAreaOfCoverageFormProps = {
    formMainProps: {
      form: updateAreaOfCoverage,
    },
  };

  const { confirm } = Modal;

  const onClickUpdateAreaOfCoverage = async () => {
    confirm({
      centered: true,
      title: "Update All",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure you want to update all this area of coverages?",
      cancelText: "Cancel",
      async onOk() {
        const isSuccess = await updateAreaOfCoverages(
          providers_area_of_coverage
        );
        setareaOfCoveragesTableMeta({ ...areaOfCoveragesTableMeta, limit: 10 });
        return isSuccess;
      },
    });
  };

  return (
    <ProvidersAreaOfCoverageIndexComponent
      updateAreaOfCoverageModalStatus={updateAreaOfCoverageModalStatus}
      handleChangeUpdateAreaOfCoverageModalStatus={onChangeUpdateModalStatus}
      updateAreaOfCoverageFormProps={updateAreaOfCoverageFormProps}
      onClickUpdateAreaOfCoverage={onClickUpdateAreaOfCoverage}
      areaOfCoverageList={areaOfCoverageList}
      onPaginationChange={onPaginationChange}
    />
  );
};
