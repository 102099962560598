import { Modal, Form } from "antd";
import { ModalProps } from "../../helpers/interfaces/ModalInterfaces";

export const ModalComponent = ({
  isModalOpen,
  onCancel,
  onOK,
  header,
  form,
  title,
}: ModalProps) => {
  return (
    <Modal
      title={title}
      open={isModalOpen}
      onOk={onOK}
      onCancel={onCancel}
      centered
    >
      {header}
      <Form form={form.formMainProps.form} layout="vertical">
        {form.formItems.map((item) => (
          <Form.Item name={item.itemProp.name} label={item.itemProp.label}>
            {item.itemComponent}
          </Form.Item>
        ))}
      </Form>
    </Modal>
  );
};
