import { Row, Col } from "antd";
import { LoginFormComponent } from "./form/LoginFormComponent";

type LoginProps = {
  loginForm: any;
  onSubmitForm: () => void;
};

export const LoginIndexComponent = ({
  loginForm,
  onSubmitForm,
}: LoginProps) => {
  return (
    <Row>
      <Col span={13}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <LoginFormComponent form={loginForm} onSubmitForm={onSubmitForm} />
        </div>
      </Col>
      <Col
        span={11}
        style={{
          textAlign: "center",
          background: "rgb(22 119 255 / 77%)",
          height: "100vh",
          opacity: 0.7,
        }}
      />
    </Row>
  );
};
