import { message } from "antd";
import { UpdateProviderTypes } from "../../api/UpdateProviderTypes";
import { useResponseProviderTypes } from "../global/useResponseProviderTypes";

export interface updateData {
  provider_type_name?: string;
}

export const useUpdateProviderTypes = async (
  provider_type_id: number,
  payload: any
) => {
  const loadingMessage =
    payload.is_active === 0
      ? "Archiving Provider Type"
      : "Updating Provider Type";
  await message.loading(loadingMessage + "..", 2);

  const response = await UpdateProviderTypes(provider_type_id, payload);

  return useResponseProviderTypes(response);
};
