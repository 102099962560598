import { useState } from "react";

export const useChangeCreateModalStatus = () => {
  const [status, setStatus] = useState(false);

  const onChange = () => {
    setStatus(!status);
  };

  return {
    onChange,
    status,
  };
};
