import { create } from "../../api/API";
import { encrypt } from "n-krypta";

export const useAuthenticateUser = () => {
  var url = `${process.env.REACT_APP_AUTOMART_ADMIN_API}/authentications/login`;
  var headers = {};

  const login = async (email_address: string, password: string) => {
    const response = await create(
      url,
      { email_address, password, platform: "Qmarketz Installment" },
      headers
    );

    if (response.status === 204) {
      return false;
    }

    const secretKey = process.env.REACT_APP_SECRET_KEY || "QMZ";

    const { jwt, permissions, user_info, role } = response.data.data;

    const encryptedPermissions = encrypt(permissions, secretKey);

    localStorage.setItem("permissions", encryptedPermissions);
    localStorage.setItem("jwt", JSON.stringify(jwt));
    localStorage.setItem("userInfo", JSON.stringify(user_info));
    localStorage.setItem("role", JSON.stringify(role));

    return true;
  };

  return { login };
};
